.ck.ck-content ul,
.ck.ck-content ul li {
    list-style-type: inherit;
}

.ck.ck-content ul {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
    list-style-type: decimal;
}
.ck.ck-content ol {
    /* Default user agent stylesheet, you can change it to your needs. */
    padding-left: 40px;
}
